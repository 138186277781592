import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/Home.vue";
import MovieDetail from "../views/Movie/Detail/index.vue";
import Shop from "../views/Shop/Shop.vue";

const routes = [
  {
    path: "",
    name: "index",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/movie/detail/:id",
    name: "movie_detail",
    component: MovieDetail,
  },
  {
    path: "/coupon/:type",
    name: "coupon",
    component: () =>
      import(/* webpackChunkName: "couponList" */ "../views/Coupon/index.vue"),
  },
  {
    path: "/coupon/detail/:id",
    name: "coupon_detail",
    component: () =>
      import(/* webpackChunkName: "couponList" */ "../views/Coupon/Detail.vue"),
  },
  {
    path: "/shop/:type?",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/shop/detail/:id",
    name: "Shop_detail",
    component: () =>
      import(/* webpackChunkName: "shopDetail" */ "../views/Shop/Detail.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/About.vue"),
  },
  // {
  //   path: "/product",
  //   name: "product/list",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "productList" */ "../views/Movie/List/index.vue"
  //     ),
  // },
  {
    path: "/movie/list",
    name: "movie_list",
    component: () =>
      import(
        /* webpackChunkName: "moveList" */ "../views/Movie/List/index.vue"
      ),
  },
  {
    path: "/active/index",
    name: "active_list",
    component: () =>
      import(/* webpackChunkName: "moveList" */ "../views/Activity/index.vue"),
  }, 
  {
    path: "/active/detail/:id",
    name: "active_detail",
    component: () =>
      import(/* webpackChunkName: "moveList" */ "../views/Activity/Detail.vue"),
  },
  {
    path: "/order/buygoods/:id",
    name: "buygoods",
    component: () =>
      import(
        /* webpackChunkName: "orderbuygoods" */ "../views/Order/Index.vue"
      ),
  },
  {
    path: "/BookCinema/index/:id",
    name: "bookcinema_list",
    component: () =>
      import(/* webpackChunkName: "moveList" */ "../views/BookCinema/index.vue"),
  }, 
];

const router = createRouter({  
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.afterEach((to) => {
  
	// 设置标题
	document.title = to.query.title || to.meta.title|| '巨好迈';
});

export default router;
