import { searchCustomerInfo, modifyMobile } from "@/api/about.js";
import { Toast } from "vant";

const state = {
  customerId: "",
  openId: "",
  nickName: "",
  mobile: "",
  avatar: "",
  businessCode: "",
  isLogin: false,
};

const getters = {
  getOpenId: (state) => {
    
    return state.openId;
  },
  getUserInfo: (state) => {
    return state;
  },
};

const mutations = {
  setOpenId(state, { openId }) {
    state.openid = openId;
  },
  setUserInfo(state, info) {
    localStorage.setItem("wxOpenId",info.openId) 
    state.customerId = info.customerId;
    state.nickName = info.nickName;
    state.mobile = info.mobile;
    state.avatar = info.avatar;
    state.businessCode = info.businessCode;
    state.isLogin = true;
    state.openId = info.openId;
  },
  setMobile(state, mobile) {
    state.mobile = mobile;
  },
};
const actions = {
  setUserInfo({ commit }, openid) {
    searchCustomerInfo({ openId: openid }).then((res) => {
      commit("setUserInfo", res.data);
    });
  },
  setMobile({ commit }, mobile) {
    modifyMobile({ openId: state.openId, mobile: mobile }).then((res) => {
      if (res.code === 200) {
        commit("setMobile", mobile);
        Toast(res.msg);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
