<template>
  <div>
    <router-view />
    <van-tabbar route>
      <van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/movie/list" icon="video-o"
        >电影</van-tabbar-item
      >
      <van-tabbar-item replace to="/shop" icon="shop-o">商城</van-tabbar-item>
      <van-tabbar-item replace to="/about" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss">
body {
  font-size: 16px;
}


.van-toast{
font-size: 22px !important;
line-height: 26px !important;
min-width: 70% !important;
top: 48% !important;
}
.van-button__text{
  font-size: 22px !important;
}
</style>
