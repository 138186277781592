import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import { ImagePreview } from "vant";

import { Lazyload } from "vant";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import "vant/lib/index.less";

router.beforeEach((to, from, next) => {
  console.log(to.path, "path");
  if (to.path === "/outlink") {
    console.log(to.path, "to.path", to.query, "query");
    window.location.href = to.query.link;
  } else {
    if (
      localStorage.getItem("wxOpenId") &&
      store.getters["user/getUserInfo"].isLogin === false
    ) {
      store.dispatch("user/setUserInfo", localStorage.getItem("wxOpenId"));
      console.log("有本地缓存但是未登录");
    }
    next();
  }
});
// require("./mock/index.js");

createApp(App)
  .use(store)
  .use(router)
  .use(Vant)
  .use(Lazyload, {
    lazyComponent: true,
  })
  .use(ImagePreview)
  .use(Vue3VideoPlayer, {})
  .mount("#app");
