<template>
  <div>
    <van-list
      v-model:loading="state.loading"
      :finished="state.finished"
      finished-text="没有更多了"
      :offset="500"
      @load="onLoad" 
    >
      <van-cell v-for="(item, index) in state.list" :key="index">
        <slot :item="item" />
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { reactive, toRefs, unref } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    apiParams: {
      type: Object,
      default: () => {
        return null;
      },
    },
    api: {
      type: Function,
      default: () => {
        return undefined;
      },
    },
  },
  setup(props) {
    const store = useStore();
    const params = reactive({
      api: props.api,
    });
    const page = reactive({
      pageSize: 20,
      pageIndex: 1,
    });
    const state = reactive({
      list: [],
      error: false,
      finished: false,
      loading: false,
    });
    const onLoad = async () => {
      console.log(page, "触发");
      const { api: propsApi } = {
        ...toRefs(params),
      };
      if (propsApi !== undefined) {
        var doApi = unref(propsApi);
        console.log(props.apiParams, "params");
        let openId = store.getters["user/getOpenId"];
        let paramsJson = Object.assign(page, props.apiParams);
        if (openId) {
          paramsJson = Object.assign(paramsJson, {
            openId: openId,
          });
          console.log(paramsJson, "paramJson");
        }
        const res = await doApi(paramsJson);
        state.loading = false;
        console.log(res, "res");
        if (res.code === 200) {
          res.data.dataList.forEach((data) => {
            state.list.push(data);
          });
          page.pageIndex++;
          state.finished = false;
          console.log(page, "page");
          if (page.pageSize * page.pageIndex >= res.data.totalCount) {
            state.finished = true;
          }
        }
      }
    };
    return { onLoad, state };
  },
};
</script>

<style lang="scss" scoped>
</style>