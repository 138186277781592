<template>
  <div class="ts-container">
    <div
      class="ts-item"
      :style="{ height: style.height, width: style.width }"
      v-for="(item, index) in data"
      :key="index"
    >
      <slot :item="item" :index="index"></slot>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";

export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    api: {
      type: Function,
      default: () => {
        return null;
      },
    },
    styleRef: {
      type: Object,
      default: () => {
        return {
          width: "4.5rem",
          height: "auto",
        };
      },
    },
  },
  setup(props) {
    const params = reactive({
      data: props.list,
      api: props.api,
    });
    const style = ref(null);
    const data = ref([]);
    // const styleRef = ref("width:20rem;");
    onMounted(() => {
      const { data: propsData } = {
        ...toRefs(params),
      };
      style.value = props.styleRef;
      console.log(props.styleRef, "styleRef");
      if (propsData.value) {
        data.value = propsData.value;
      }
      // console.log(propsWidth.value, "propsWidth");
      // if (propsWidth.value) {
      //   styleRef.value = `width:${propsWidth.value};`;
      // }
      // console.log(propsHeight.value, "propsWidth");
      // if (propsHeight.value) {
      //   styleRef.value += `height:${propsHeight.value};`;
      // }
      // console.log(styleRef.value, "style");
    });
    const getStyle = () => {
      console.log(props.styleRef, `1style`);
      return props.styleRef;
    };
    return {
      data,
      style,
      getStyle,
    };
  },
};
</script>

<style lang="scss" scoped>
.ts-container {
  width: 95%;
  margin: 0 auto;
  overflow: scroll;
  white-space: nowrap;
}
.ts-item {
  display: inline-block;
  width: 20rem;
  overflow: hidden;
  margin-right: 10px;
}
.poster {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 6px;
}
.poster > img {
  height: 100%;
  width: 100%;
}

.default-img-bg {
  background-color: #e1e1e1;
  background-size: 100% 100%;
}

.name {
  margin: 0;
  font-size: 13px;
  color: #222;
  margin-bottom: 3px;
}
.inline-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::-webkit-scrollbar {
  height: 0 !important;
}
</style>