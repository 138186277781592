<template>
  <div>
    <div class="content-header">
      <section>
        <h5>
          <p class="title">{{ info.movieTitle }}</p>
        </h5>

        <p class="subTitle">{{ info.movieSubTitle }}</p>
        <h6>
          评分: {{ info.movieScore }}
          <p>/ {{ info.movieType }}</p>
          <p>/ {{ info.movieLength }}</p>
          <p></p>
        </h6>
        <h6>
          上映时间: <span class="showTime">{{ info.showTime }}</span>
        </h6>
      </section>
    </div>
    <van-divider />
    <detail-content v-if="info" :data="info" />
    <van-divider />
    <div class="movie-photo">
      <van-tabs v-model:active="activeName">
        <van-tab title="视频" name="video">
          <transverse-scroll :list="list" :styleRef="ratio">
            <template v-slot:default="defaultProps">
              <video
                v-if="defaultProps.item.videoUrl"
                :src="defaultProps.item.videoUrl"
                :poster="defaultProps.item.cover"
                class="video-element"
                @click="fullscreenPlay"
                controls
              ></video>
              <!-- <vue3-video-player
                :src="defaultProps.item.videoUrl"
                @play="fullscreenPlay"
                :cover="defaultProps.item.cover"
              ></vue3-video-player> -->
            </template>
          </transverse-scroll>
        </van-tab>
        <van-tab title="剧照" name="photo">
          <transverse-scroll :list="picList" :styleRef="ratio">
            <template v-slot:default="defaultProps">
              <div class="poster default-img-bg">
                <img
                  :src="defaultProps.item.src"
                  @click="
                    preview({
                      images: picList.map((p) => p.src),
                      startPosition: defaultProps.index,
                    })
                  "
                  onerror="this.style.visibility='hidden'"
                />
              </div>
            </template>
          </transverse-scroll>
        </van-tab>
      </van-tabs>
    </div>

    <div></div>
  </div>
</template>

<script>
import DetailContent from "./content.vue";
import TransverseScroll from "../../../../components/TransverseScroll.vue";
import { onMounted, reactive, ref } from "vue";
import { ImagePreview } from "vant";
export default {
  components: { DetailContent, TransverseScroll },
  props: {
    data: Object,
  },
  setup(props) {
    const info = ref(props.data);
    const activeName = ref("photo");
    const list = ref(null);
    const picList = ref(null);
    const isFullScreen = ref(false);
    const ratio = reactive({
      height: "",
      width: "",
    });
    let videoRef = null;
    onMounted(() => {
      ratio.width = document.body.clientWidth / 2 + "px";
      ratio.height = (document.body.clientWidth / 2 / 16) * 11 + "px";
      console.log(ratio, "比例");
      list.value = info.value.videos?.dataList?.map((d) => {
        return { title: d.videoTitle, videoUrl: d.videoUrl, cover: d.videoImg };
      });
      picList.value = info.value.pictures?.dataList?.map((d) => {
        return { src: d.pictureUrl };
      });
    });
    const fullscreenPlay = (event) => {
      console.log(event, "e");
      videoRef = event.target;
      videoRef.requestFullscreen();
    };
    const preview = (src) => {
      ImagePreview(src);
    };
    document.addEventListener("fullscreenchange", () => {
      isFullScreen.value = !isFullScreen.value;
      if (!isFullScreen.value) {
        videoRef?.pause();
      }
    });

    return { list, activeName, info, fullscreenPlay, picList, preview, ratio };
  },
};
</script>

<style lang="scss" scoped>
.van-divider {
  margin: 5px;
}
.title {
  margin-block-start: 0.3rem;
  margin-block-end: 0.3rem;

  font-size: 1.3rem;
  display: inline-block;
  padding-left: 0.7rem;
}
.movie-photo p {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
.movie-photo {
  margin-bottom: 3rem;
}
.poster {
  height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 6px;
}
.poster > img {
  height: 100%;
  width: 100%;
}

.content-header {
  height: 7.5rem;
}
.subTitle {
  padding-left: 0.7rem;
  font-size: 0.95rem;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
}
.showTime {
  color: #ff9600;
  font-weight: bold;
}
.video-element {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
::v-deep h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}
::v-deep h6 {
  padding-left: 0.7rem;
  font-weight: normal;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  margin-block-start: 0;
  margin-block-end: 0;
}
::v-deep p {
  position: relative;
  display: inline-block;
}
::v-deep .van-divider {
  border-color: #dddddd;
}
</style>