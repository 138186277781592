import { post } from "@/utils/http.js";

export const searchBanner = () => {
    return post("/Picture/SearchBanner");
};

export const searchActive = (params) => {
    return post("/Activity/Collection", params);
};

export const SearchMovies = () => {
    return post("/movie/SearchMoviesBC");
};

export const searchActiveInfo = (params) => {
    return post("/Activity/Info", params)
}