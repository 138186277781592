<template>
  <div>
    <van-search
      v-model="keyword"
      placeholder="请输入搜索关键词"
      input-align="center"
    />
    <van-tabs v-model:active="active" animated sticky swipeable>
      <van-tab
        :name="item.value"
        v-for="(item, index) in categories"
        :title="item.title"
        :key="index"
      >
        <card-list :api="searchGoods" :apiParams="{ goodType: item.value }">
          <template v-slot:default="defaultProps">
            <div class="item">
              <div
                class="image"
                @click="gotoDetail(defaultProps.item.goodId)"
                style
              >
                <img :src="defaultProps.item.imgUrl" />
              </div>
              <div class="item-desc">
                <div class="desc">
                  <p class="movie-title">{{ defaultProps.item.goodTitle }}</p>
                </div>
                <div>
                  <div class="item-label">
                    <p class="movie-subtitle">
                      {{ defaultProps.item.goodLabel }}
                    </p>
                  </div>
                  <div class="item-price">
                    <span class="item-curry">¥</span
                    >{{ defaultProps.item.salePrice }}
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ defaultProps }} -->
          </template>
        </card-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { searchGoods } from "@/api/product.js";
import CardList from "@/components/CardList.vue";
import { useRoute, useRouter } from "vue-router";
export default {
  components: { CardList },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const active = ref("");

    const keyword = ref("");
    const categories = ref([
      { title: "全部", value: "" },
      {
        title: "电影周边",
        value: "Warp",
      },
      {
        title: "潮流盲盒",
        value: "BlindBox",
      },
      {
        title: "原创设计",
        value: "Original",
      },
    ]);
    const gotoDetail = (goodId) => {
      console.log(goodId, "goodId");
      router.push({
        path: `/shop/detail/${goodId}`,
      });
    };
    onMounted(() => {
      active.value = route.params.type;
    });

    return { categories, searchGoods, active, keyword, gotoDetail };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__content {
  overflow-y: scroll;
  height: calc(100vh - 148px);
}
::v-deep .van-cell {
  float: left;
  width: 50%;
  background: transparent;
  padding: 10px 10px 0px 10px;
}
.item {
  border-radius: 0.85em;
  border: 1px solid #efefef;
  box-shadow: 8px 5px 5px 8px #efefef;
  background: #ffffff;
}
.movie-title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desc {
  width: 100%;
}
.image {
  width: 100%;
  height: 10rem;
  border-radius: 0.85em;
}
.image > img {
  border-radius: 0.85em 0.85em 0 0;
  width: 100%;
  height: 100%;
}
.movie-subtitle {
  font-size: 0.7rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2rem;
  padding: 0rem 0.3rem;
}
.item-label {
  float: left;
  background-color: #ff9600;
  color: #ffffff;
  border-radius: 0.3rem;
}
.item-price {
  text-align: right;
  color: #ff9600;
  font-weight: bold;
}
.item-curry {
  font-size: 0.5rem;
  padding-right: 0.1rem;
  font-weight: normal;
}
.item-desc {
  padding: 0.3rem 0.5rem;
}
::v-deep .van-swipe {
  overflow-y: scroll;
}
</style>