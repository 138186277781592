import { post } from "@/utils/http.js";


export const wechatAuth = () => {
    return post('/WeChat/AuthorizeUrl', { codeType: 0 });
}

export const getUserInfo = () => {
    return post('/WeChat/AuthorizeUrl', { codeType: 1 })

}