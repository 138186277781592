<template>
  <div>
    <van-row>
      <van-col span="7">
        <div class="list-title">
          <label class="title-lab">{{ list_title }}</label>
        </div>
      </van-col>
      <van-col span="17">
        <div class="list-more">
          <p class="more" @click="more">更多>></p>
        </div>
      </van-col>
    </van-row>
    <div class="content">
      <van-grid :border="true" :gutter="5" :column-num="3">
        <van-grid-item v-for="(item, index) in listData" :key="index">
          <van-image
            class="item-image"
            :src="item.imgUrl"
            @click="gotoDetail(item.goodId)"
          />
          <div class="item-desc">
            <p class="title">
              {{
                item.goodTitle.length > length
                  ? item.goodTitle.substring(0, length) + "..."
                  : item.goodTitle
              }}
            </p>
          </div>
          <div class="sub-price">
            <span class="curry">¥</span
            ><span class="price">{{ item.salePrice }}</span>
          </div>
        </van-grid-item>
        <!-- <van-grid-item>
          <van-image
            class="item-image"
            src="https://img.yzcdn.cn/upload_files/2019/08/01/FplfvcZY4RwSSYBjV6aHlJmePSgC.jpg!large.webp"
          />
          <div class="item-desc">
            <p class="sub-title">物鸣便携式牙刷盒</p>
          </div>
          <div class="sub-price">
            <span class="curry">¥</span><span class="price">23</span>
          </div>
        </van-grid-item>
        <van-grid-item>
          <van-image
            class="item-image"
            src="https://img.yzcdn.cn/upload_files/2020/10/29/FsaMr_jIjEExqa4rp1NIV7ie6qGY.PNG!large.webp"
          />
          <div class="item-desc">
            <p class="sub-title">鸣折叠衣架</p>
          </div>
          <div class="sub-price">
            <span class="curry">¥</span><span class="price">898</span>
          </div>
        </van-grid-item> -->
      </van-grid>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, unref } from "vue";
import { searchGoods } from "@/api/product.js";
import {} from "vant";
import { useRouter } from "vue-router";
export default {
  props: {
    title: String,
    api: Function,
  },
  setup(props) {
    const list_title = ref(props.title);
    const goodType = ref(null);
    const listData = ref([]);
    const router = useRouter();
    const length = ref(6);
    onMounted(() => {
      let width = document.body.clientWidth;
      if (width > 400) {
        length.value = 7;
      }
      switch (list_title.value) {
        case "原创设计":
          goodType.value = "Original";
          break;
        case "潮流盲盒":
          goodType.value = "BlindBox";
          break;
        case "电影周边":
          goodType.value = "Warp";
          break;
        default:
          break;
      }
      load();
    });

    const load = () => {
      searchGoods({
        pageIndex: 1,
        pageSize: 3,
        goodType: goodType.value,
      }).then((res) => {
        listData.value = res.data.dataList;
        // console.log(3 - listData.value.length, "count");
        let pushCount = 3 - listData.value.length;
        for (let index = 0; index < pushCount; index++) {
          console.log(index, "index");
          listData.value.push({
            goodId: "",
            goodLabel: "",
            goodTitle: "无内容",
            goodType: goodType.value,
            imgUrl: "https://img01.yzcdn.cn/vant/custom-empty-image.png",
            originalPrice: "--",
            salePrice: "--",
          });
        }
        console.log(listData.value, "data");
      });
    };
    const more = () => {
      let type = unref(goodType.value);
      console.log(type, "tag");
      router.push({
        path: `/shop/${type}`,
      });
    };
    const gotoDetail = (id) => {
      router.push({
        path: `/shop/detail/${id}`,
      });
    };

    return {
      list_title,
      searchGoods,
      goodType,
      listData,
      more,
      gotoDetail,
      length,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  line-height: 1.5rem;
  border: 2px solid #ff9600;
  height: 1.5rem;
  border-radius: 0 2rem 2rem 0;
  margin-bottom: 0.5rem;
  background-color: #ff9600;
  color: #ffffff;
}
.list-more {
  float: right;
  line-height: 1.5rem;
  border: 2px solid #ff9600;
  height: 1.5rem;
  border-radius: 2rem 0 0 2rem;
  margin-bottom: 0.5rem;
  background-color: #ff9600;
  color: #ffffff;
  width: 4.5rem;
}
.title {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  width: 0;
  text-overflow: ellipsis;
}
.list-title > .title-lab {
  margin-left: 1.5rem;
}
::v-deep .van-image__img {
  border-radius: 0.3rem;
}
.item-desc {
  font-size: 0.8rem;
  white-space: nowrap;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sub-title {
  flex: 1;
  width: 0;
}
.sub-price {
  text-align: left;
  width: 100%;
}
.curry {
  color: #ff9600;
}
.price {
  color: #ff9600;
}
.content {
  margin-bottom: 1.5rem;
}
.more {
  height: 1.5rem;
  line-height: 1.5rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  text-align: end;
}
::v-deep [class*="van-hairline"]::after {
  border: 1px solid #efefef;
  box-shadow: 8px 5px 5px 8px #efefef;
}

::v-deep .van-grid-item__content--center {
  border-radius: 0.85rem;
  padding: 0.4rem 0.5rem;
}
</style>