<template>
  <div class="home">
    <banner />
    <swiper />
    <quick-menu />
    <list :title="`电影周边`" />
    <list :title="`潮流盲盒`" />
    <list :title="`原创设计`" />
    <div class="end"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "./Header/banner.vue";
import Swiper from "./Header/swiper.vue";
import QuickMenu from "./Header/quick_menu.vue";
import List from "./List/List.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted } from "vue";

export default {
  name: "Home",
  components: { Banner, Swiper, QuickMenu, List },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      if (route.query.opid) {
        console.log(store, "store");
        store.dispatch("user/setUserInfo", route.query.opid);
        router.push({ path: `/about` });
      }
      console.log(route.query.opid, "openid");
    });
  },
};
</script>
<style lang="scss" scoped>
.end {
  height: 6rem;
  width: 100%;
}
</style>
