<template>
  <div class="quick-menu">
    <div class="menu-content">
      <van-grid>
        <van-grid-item
          v-for="(item, index) in menu"
          :key="index"
          :icon="item.icon"
          :text="item.title"
          @click="goTo(item.url)"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
import couponIcon from "@/assets/icons/icon_03.png";
import daijinquan from "@/assets/icons/icon_04.png";
import active from "@/assets/icons/icon_05.png";
import movie from "@/assets/icons/icon_06.png";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const menu = [
      {
        title: "优惠券",
        icon: couponIcon,
        url: "/coupon/Coupon",
      },
      {
        title: "代金券",
        icon: daijinquan,
        url: "/coupon/Voucher",
      },
      {
        title: "包场",
        icon: active,
        url: "/Active/index",
        // onclick: goToCoupon.bind(null, "Coupon"),
      },
      {
        title: "电影",
        icon: movie,
        url: "/movie/list",
        // onclick: goToCoupon.bind(null, "Coupon"),
      },
    ];
    function goTo(url) {
      router.push(url);
    }
    return { menu, goTo };
  },
};
</script>

<style lang="scss" scoped>
.quick-menu {
  height: 6.5rem;
  text-align: center;
}
.menu-content {
  margin: 0 auto;
  width: 100%;
}
::v-deep [class*="van-hairline"]::after {
  border: 0;
}
::v-deep [class*="van-icon-"]::before {
  color: #dddddd;
}
::v-deep .van-grid-item__icon {
  font-size: 3rem;
}
::v-deep .van-grid-item__icon + .van-grid-item__text {
  margin-top: 0px;
}
::v-deep .van-grid-item__content {
  padding: 8px 8px;
}
::v-deep .van-grid-item__text {
  font-size: 16px;
}
</style>