<template>
  <div class="gst-header">
    <van-swipe>
      <van-swipe-item v-for="(item, index) in swipeModels" :key="index">
        <!-- {{item}} -->
        <img :src="item.image" style="width: 100%; height: 100%" @click="redirect(item)" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { searchBanner } from "@/api/home.js";
export default {
  setup() {
    const swipeModels = ref([]);
    onMounted(() => {
      searchBanner().then((res) => {
        console.log(res);
        if (res.code === 200) {
          swipeModels.value = res.data.banners;
        }
      });
    });
    const router = useRouter();
    function redirect(model) {
      switch (model.type) {
        case 1:
          router.push({
            path: "outlink",
            query: { link: model.url },
          });
          break;

        default:
          console.log(model.url, "linkhref");
          router.push({ path: `/${model.url}` });
          break;
      }
    }

    return { current: 0, swipeModels, redirect };
  },
};
</script>

<style lang="scss" scoped>
.gst-header {
  height: 9rem;
  background: transparent;
  background-color: #fff;
  text-align: center;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #ddd;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
::v-deep .van-swipe {
  width: 98%;
  height: 100%;
  border-radius: 0.85rem;
  margin: 0 auto;
}
</style>