import { post } from "@/utils/http.js";

export const modifyMobile = (params) => {
  return post("/Customer/ModifyMobile", params);
};

export const saveAddress = (params) => {
  return post("/Customer/SaveAddress", params);
};

export const searchCustomerInfo = (params) => {
  console.log(params,'params');
  return post("/Customer/SearchCustomerInfo", params);
};

export const searchCouponRecords = (params) => {
  return post("/Customer/SearchCouponRecords", params);
};

export const searchCouponTypeCount = (params) => {
  return post("/Customer/SearchCouponTypeCount", params);
};

export const searchAddress = (params) => {
  return post("/Customer/SearchAddress", params);
};

export const deleteCustomerAddress = (params) => {
  return post("/Customer/DeleteAddress", params);
};
