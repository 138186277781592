<template>
  <div>
    <van-nav-bar
      :title="movieInfo?.movieTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <div class="h-video" :style="{ height: videoHeight + 'px' }">
        <video
          v-if="movieInfo"
          :src="movieInfo.mainVideoUrl"
          :poster="movieInfo.mainVideoImgUrl"
          class="video-element"
          controls
        ></video>
      </div>
    </div>
    <div class="content">
      <e-content v-if="movieInfo" :data="movieInfo"></e-content>
    </div>
    <div class="empty"></div>
    <div class="fixButton">
      <van-button type="primary" class="btnBaoC" :block="true" @click="gotoBaoC(movieInfo.movieId)"
        >去包场</van-button>
      <van-button type="primary" class="btnOrder" :block="true" @click="gotoBuyTicket"
        >去买票</van-button>
    </div>
  </div>
</template>

<script>
import EContent from "./content/index.vue";
import { searchMoviceInfo } from "@/api/movie.js";
import { ref, onBeforeMount, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  components: { EContent },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const movieInfo = ref(null);
    const videoHeight = ref(null);

    onBeforeMount(async () => {
      videoHeight.value = (document.body.clientWidth / 16) * 9;
      // console.log(videoHeight, "videoHeight");
      if (route.params.id) {
        // console.log(route.params.id, "id");
        let res = await searchMoviceInfo(route.params.id);
        // console.log(res, "resp");
        if (res.code === 200) {
          movieInfo.value = res.data;
        }
        await nextTick();
        // console.log(movieInfo.value, "movie");
      }
      // searchMovieInfo();
    });
    const checkMovieInfo = (info) => {
      // console.log(info, "infofff");
      return info.MainVideoUrl !== undefined;
    };
    const onClickLeft = () => {
      router.back();
    };
    const gotoBuyTicket = () => {
      router.push({
        path: "/outlink",
        query: {
          link: movieInfo.value.redirectionUrl,
        },
      });
    };
    const gotoBaoC = (id) => { 
      router.push({ path: `/active/detail/${id}` });
    }; 
    return { movieInfo, checkMovieInfo, onClickLeft, videoHeight,gotoBuyTicket,gotoBaoC };
  },
};
</script>

<style lang="scss" scoped>
.fixButton {
  position: fixed;
  width: 100%;
  bottom: 50px;
}
.h-video {
  // height: 10.8rem;
  background-color: black;
}
.video-element {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.empty {
  height: 50px;
}
.btnBaoC{
  width: 50% !important;
  float: left;
  border: solid 1px #2C9EA8 !important;
  background-color: #2C9EA8 !important;
}
.btnOrder{
  width: 50% !important;
  float: left;
}
</style>