<template>
  <div class="content">
    <p class="title">简介</p>
    <p class="description">{{ data.introduction }}</p>
    <div class="actor">
      <p class="title">演职人员</p>
      <van-icon style="float: right" name="arrow" />
      <transverse-scroll v-if="actorList" :list="actorList">
        <template v-slot:default="defaultProps">
          <div class="poster default-img-bg">
            <img
              :src="defaultProps.item.img"
              @click="
                preview({
                  images: actorList.map((p) => p.img),
                  startPosition: defaultProps.index,
                })
              "
              onerror="this.style.visibility='hidden'"
            />
          </div>
          <h5 class="name line-ellipsis">{{ defaultProps.item.name }}</h5>
          <h5 class="name line-ellipsis">{{ defaultProps.item.title }}</h5>
        </template>
      </transverse-scroll>
    </div>
  </div>
</template>

<script>
import TransverseScroll from "@/components/TransverseScroll.vue";
import { onMounted, ref } from "vue";
import { ImagePreview } from "vant";
export default {
  components: { TransverseScroll },
  props: {
    data: Object,
  },
  setup(props) {
    const actorList = ref(null);
    const info = ref(props.data);
    onMounted(() => {
      // console.log(info, "props");
      actorList.value = info.value.actors?.dataList?.map((d) => {
        // console.log(d, "arctor");
        return {
          title: d.leadingActor,
          name: d.leadingName,
          content: "",
          img: d.leadingActorImgUrl,
        };
      });

      // console.log(actorList, "演职人员表");
    });
    const preview = (src) => {
      ImagePreview(src);
    };
    // [
    //   {
    //     title: "阿凡达",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "https://p1.meituan.net/170.230/moviemachine/f7086730608eb956c7c27e9eb3b5cc415439205.jpg",
    //   },
    //   {
    //     title: "你好，李焕英",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "https://p0.meituan.net/170.230/movie/48774506dc0e68805bc25d2cd087d1024316392.jpg",
    //   },
    //   {
    //     title: "波斯语课",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "http://p0.meituan.net/w.h/movie/b87e99c121df5dcb00ad6dddf61bd6ee2246726.jpg",
    //   },
    //   {
    //     title: "阿凡达",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "https://p1.meituan.net/170.230/moviemachine/f7086730608eb956c7c27e9eb3b5cc415439205.jpg",
    //   },
    //   {
    //     title: "你好，李焕英",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "https://p0.meituan.net/170.230/movie/48774506dc0e68805bc25d2cd087d1024316392.jpg",
    //   },
    //   {
    //     title: "波斯语课",
    //     name: "test",
    //     content: "sadsfzxcv",
    //     img:
    //       "http://p0.meituan.net/w.h/movie/b87e99c121df5dcb00ad6dddf61bd6ee2246726.jpg",
    //   },
    // ];
    // function getItem(item) {
    //   // console.log(item, 111);
    // }
    return { info, actorList, preview };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  font-weight: bold;
}
.actor p {
  position: relative;
  display: inline-block;
}
.poster {
  width: 100%;
  position: relative;
}
.poster > img {
  height: 100%;
  width: 100%;
}

.line-ellipsis {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: normal;
}
.content p {
  margin-block-start: 0.3rem;
  margin-block-end: 0.3rem;
  padding-left: 0.7rem;
  padding-right: 0.5rem;
}
.description {
  font-size: 1rem;
}
</style>