<template>
  <div>
    <div class="banner"> 
      <div class="banner-search-input"> 
        <img src="http://file.juhaomaish.cn/banner/jhmbanner.png" style="width: 100%; height: 100%"  />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.banner {
  
}
.banner > .func-list {
  margin-top: 3rem;
  margin-right: 3rem;
  float: right;
  font-size: 3rem;
}
.banner-search-input {
  width: 100%; 
}
::v-deep .van-search {
  width: 96%;
}

// ::v-deep .van-search__content {
//   border: solid 0.1rem #575757;
//   background-color: transparent;
// }
// ::v-deep .van-search__content > .van-cell {
//   line-height: 100%;
//   color: #989393;
// }
// ::v-deep .van-search {
//   padding: 0px;
//   width: 70rem;
//   margin-left: 1.4rem;
//   // height: 1rem;
//   background-color: transparent;
// }
// ::v-deep ::-webkit-input-placeholder {
//   color: #989393;
//   font-size: 12px;
//   line-height: 100%;
//   // margin-top: 0.2rem;
//   text-align: center;
// }
// ::v-deep .van-field__control {
//   line-height: 100%;
//   font-size: 12px;
//   color: #fff;
// }
</style>